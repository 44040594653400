@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');


html,
body,
#root,
.app {
    height: 100%;
    width: 100%;
    scroll-behavior: smooth;
    font-family: "Inter", sans-serif;
}

::-webkit-scrollbar {
    width: 2px;
}

::-webkit-scrollbar-track {
    background: #fff;
}

::-webkit-scrollbar-thumb {
    background-color: #fee2a2;
}
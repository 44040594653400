.container-div {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('../../assets/background.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: opacity(50%);
}

.overlay {
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.75); /* Black background with opacity */
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  color: #f9bd33;
  text-align: center;
}

.subtitle {
  color: rgb(218, 218, 218);
  text-align: center;
  margin-bottom: 2rem;
}

.inputs_container {
  display: flex;
  flex-direction: column;
}

.inputs_container input {
  width: 95%;
  margin: 1.5rem auto;
  padding: 1rem;
  border-radius: 15px;
  font-size: 1rem;
  outline: 0;
  border: 0;
}

.login_button {
  display: block;
  margin: 1rem auto 1rem auto;
  width: 55%;
  padding: 0.75rem 1rem;
  border-radius: 15px;
  outline: 0;
  font-size: 1rem;
  cursor: pointer;
  background-color: #f9bd33;
  color: #000;
  border: 2px solid transparent;
}

.login_button:hover {
  border: 2px solid #f9bd33;
  background-color: #000;
  color: #fff;
}

.link_container {
  display: flex;
  justify-content: center;
}

.small {
  color: rgb(218, 218, 218);
  text-align: center;
  font-size: 0.85rem;
  text-decoration: none;
  margin-bottom: 2rem;
  cursor: pointer;

}

.small:hover{
  color: #f9bd33;
}

@media only screen and (max-width: 620px) {
  .inputs_container input {
    width: 100%;
  }

  .login_button {
    width: fit-content;
    padding-left: 5%;
    padding-right: 5%;
  }

  .error_msg {
    padding-left: 0.2rem;
  }
}

@media only screen and (max-height: 670px) {
  .subtitle {
    margin-bottom: 2rem;
  }

  .small {
    margin-bottom: 3rem;
  }
}
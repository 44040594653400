.proImg{
    width: auto;
    height: auto;
    max-width: 50%;
    max-height: 20%;
    overflow: hidden;
}

.proImg img{
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    cursor: pointer;
}

.enlargedImage{
    max-width: 60%;
    max-height: 60%;
    cursor: pointer;
}

.enlargedImage:hover{
    background-color: rgba(0, 0, 0, 0.5);
}